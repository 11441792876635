<template>
    <div class="container">
        <el-button v-if="!showData" type="primary" plain @click="handleScan" icon="el-icon-full-screen" class="scan_btn">扫一扫</el-button>
        <div v-if="showData">
            <ul>
                <li>访客姓名：{{resultData.visitorName}}</li>
                <li>联系方式：{{resultData.visitorPhone}}</li>
                <li>身份证号码：{{resultData.visitorIdCard}}</li>
                <li class="flex">访问时间：<span style="flex: 1;">{{resultData.startTime}} 至 {{resultData.overTime}}</span></li>
                <li>访问企业：{{resultData.enterpriseName}}</li>
                <li>访问访人：{{resultData.accessName}}</li>
            </ul>
            <el-button type="info" @click="showData=false" style="display: block;margin: auto;">返回</el-button>
        </div>

    </div>
</template>

<script>
export default {
    name: 'home',
    data() {
        return {
            showData: false,
            resultData: {},

        }
    },
    methods: {
        handleScan() {
            // alert('扫码')
            this.$router.push('/scanCodePage');
        },

    },
    created() {
        if(!this.$getCookie('smUserId')) {
            this.$router.push('/login')
        }

    },
    mounted() {
        if(this.$route.params.data) {
            this.showData = true;
            this.resultData = JSON.parse(this.$route.params.data);
        }

    }
}
</script>

<style scoped>
/* .container {
    text-align: center;
    line-height: 750px;
} */
.scan_btn {
    display: block;
    margin: 100px auto;
}
ul {
    margin: 30px 30px 50px;
    padding: 10px;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
}
ul li {
    list-style: none;
    font-size: 30px;
    line-height: 56px;
    color: #333;
}
</style>